export default [
  {
    key: "companyId",
    label: "field.company",
    rules: "required",
    type: "asyn-single-selection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "nameEn",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    rules: "integer|min_value:1",
    type: "currency",
  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
